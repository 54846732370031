$backgroud-color:#090907;
$scrollbar-background:#121215;
$backgroud-color-2:#161616;
$backgroud-color-3:#181818;
$theme-color-light: #fd7000;
$theme-color-darken: #f96332;
$theme-color-darken-2: #df582b;
$theme-color-darken-3: #bb4a24;
$clickable-a-color:#0084ff;
$desc-color:#ddd;
$theme-color:#fd7000;
$text-color:#fff;
$privedit-color:#fc03c2;
$corecheat-color:#f50707;
$behind-color:#9e9e9e;
$discord-main-color:#7289DA;
$gmail-main-color:#DA524A;
$discord-theme-color:#fff;
$discord-theme-back-color:#26262B;
$discord-theme-text-color-on-back:#26262B;
$logo-desktop-width:7em;
$logo-desktop-margin:-0.45em;
$logo-tablet-width:5em;
$logo-tablet-margin:-0.45em;
$logo-mobile-width:2.89em;
$logo-mobile-margin:-0.45em;
$logo-title-ratio:1.7916;