@import '../../StyleComponents/animations.scss';
@import '../../StyleComponents/components.scss';
@import '../../StyleComponents/constants.scss';
@import '../../StyleComponents/devices.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
a{
    text-decoration: none;
}
.PanelRoot[active='1'] div.sidebar{
    transform:translate3d(0,0,0);
}
div.sidebar{
    -webkit-box-shadow: 9px 1px 26px -12px rgba(0,0,0,0.75);
    -moz-box-shadow: 9px 1px 26px -12px rgba(0,0,0,0.75);
    box-shadow: 9px 1px 26px -12px rgba(0,0,0,0.75);
    width: 260px;
    height: 100%;
    position: absolute;
    z-index: 101;
    display: flex;
    flex-direction: column;
    background-color: rgb(12, 11, 16);
    transform:translate3d(-260px,0,0);
    transition:all .5s cubic-bezier(.685,.0473,.346,1);
    .logo{
        flex: 0 1 auto;
        display: flex;
        padding:1.2rem 1.6rem;
        div.lysep-logo{
            animation: ls-mainmenu-animation 10s alternate;
            width: 25px;
            height: 25px;
        }
        div.brand{
            margin-left: 1.1rem;
            
            justify-content: center;
            vertical-align: middle;
            a{
                top:50%;
                position: relative;
                transform: translateY(-50%);
                display: flex;
                color:white;
                font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
                font-weight: 600;
                font-size: 18px;
                margin: auto;
                
            }
            
        }
    }
    .horizontal-line{
        height: 1px;
        margin: 0 1em;
        background-color: white;
        opacity: 0.6;
    }
    .wrapper{
        flex: 1 1 auto;
        flex-shrink: 0; 
        min-height: 0;
        height: 0px;
        overflow-y: auto;
	/* Firefox */
	&::-webkit-scrollbar-track {
		border-radius: 1px;
        
		width: 3px;
		display: inline-block;
	}
	&::-webkit-scrollbar {

		width: 6px;
		display: block;
        background-color: $theme-color-darken;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

        
	}
	&::-webkit-scrollbar-thumb {
        border-radius: 1px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $theme-color-darken-3;
	}
        ul.page-lists{
            
            padding-bottom: 2em;
            list-style: none;
            margin:unset;
            padding-top: 0.9rem;
            padding-left: 0.65rem;
            padding-right: 1.0rem;
            overflow-y: hidden; 
            height: fit-content;
            li{
                &[active="1"]{
                    background-color: #ffffff;
                    i{
                        color:$theme-color-darken;
                        font-size: 21px;
                        transition: ease-in-out 0.3s;
                    }
                    div.brand{
                        margin-left: 1.4rem;
                        a{
                            top:50%;
                            transform: translateY(-50%);
                            font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
                            color:$theme-color-darken;
                            font-weight: 600;
                            font-size: 10px;
                        }
                    }
                    &:hover{
                        background-color: #ffffff;
                    }
                    &:hover i {
                        color:$theme-color-darken;
                    }
                }
                display: flex;
                margin-left: 3px;
                padding:1.1rem 1rem;
                padding-top: 0.9rem;
                margin-bottom: 0.4em;
                border-radius: 30px;
                cursor: pointer;
                i{
                    color:#ffffffb3;
                    font-size: 21px;
                    transition: ease-in-out 0.3s;

                }
                transition: ease-in-out 0.3s;
                &:hover{
                 background-color: #ffffff1c;

                }
                &:hover i {
                    color:#ffffffff;
                }
                &:hover div.brand a{
                    font-weight: 600;
                    
                } 
                div.brand{
                    margin-left: 1.4rem;
                    a{
                        top:50%;
                        transform: translateY(-50%);
                        font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
                        color:white;
                        font-weight: 600;
                        font-size: 10px;
                    }
                }
                
            }
        }
    }
    .footer{
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        -webkit-box-shadow: -20px 12px 42px 9px rgba(0,0,0,0.26);
-moz-box-shadow: -20px 12px 42px 9px rgba(0,0,0,0.26);
box-shadow: -20px 12px 42px 9px rgba(0,0,0,0.26);
        border-top: 1px solid rgba(255, 255, 255, 0.116);
        flex: 0 1 40px;
        height: 40px;
        
        background-color: rgb(12, 11, 16);
        display: flex;
        flex-direction: row;
        vertical-align: middle;
        div{
            position: relative;
            top:50%;
            transform: translateY(-50%);
            height: fit-content;
            padding-left: 20px;
            a{
                color:white;
                font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
                font-weight: 600;
                font-size: 13px;
            }
        }
        
    }
}