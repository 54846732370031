div.PanelRoot{
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    background-color: #212529;
    .backdrop{
        background-color: rgba(0,0,0,0.4);
        display: none;
        &.active{
            display: block;
        }
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100%;
    }
    div.inner-wrapper{
        width: 100%;
        display: block;
        padding-top: 1rem;
    }
    div.panel-header{
        width: 100%;
        height: 70px;
        background: linear-gradient(90deg, rgba(22,17,111,1) 0%, rgb(41, 41, 144) 35%, rgb(0, 97, 189) 100%); 
        z-index: 1;
        display: block;
        position: relative;
        content: "";
        overflow: hidden;
    }
    .wrapper-panel{
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
}
