#root{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
}
*{
    margin: unset;
}

.logo-typo{
    font-family: Montserrat,Helvetica Neue,Arial,sans-serif !important;
}