.mainMuiTable{
    width: 100%;
}

.usersContainer{
    margin-top: 10px;
}

.UsersEditDialog {
    .MuiFormControl-root{
      
    }
    
}