@keyframes ls-mainmenu-animation {
	0% {
		transform: rotateZ(0deg);
	}
	20% {
		transform: rotateZ(250deg);
	}
	30% {
		transform: rotateZ(180deg);
	}
	31% {
		transform: rotateZ(170deg);
	}
	40% {
		transform: rotateZ(120deg);
	}
	50% {
		transform: rotateZ(220deg);
	}
	60% {
		transform: rotateZ(330deg);
	}
	75% {
		transform: rotateZ(300deg);
	}
	80% {
		transform: rotateZ(360deg);
	}
	85% {
		transform: rotateZ(270deg);
	}
	90% {
		transform: rotateZ(180deg);
	}
	100% {
		transform: rotateZ(0deg);
	}
}
@keyframes ls-mainmenu-animation-loading  {
	0% {
		transform: rotateZ(0deg);
	}
	80% {
		transform: rotateZ(180deg);
	}

	100% {
		transform: rotateZ(360deg);
	}
}
div.lysep-logo{
    width: fit-initial;
    height: initial;
    svg{
        fill:#fd7000;
    }
    &.true{
        svg{
            fill:#fd7000;
        }
    }
    &.center{
        margin:0 auto;
        display: block;
    }
    &.center-flex{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    &.reverse{
        svg{
            fill:white;
        }
    }
    width: 50px;
    height: 50px;
    &.animated{
        animation: ls-mainmenu-animation 10s alternate;
    }
    &.loading{
        animation: ls-mainmenu-animation-loading 2.5s infinite;
    }
    &.sm{
        width: 25px;
        height: 25px;
    }
    &.md{
        width: 50px;
        height: 50px;
    }
    &.lg{
        width: 100px;
        height: 100px;
    }
    &.xl{
        width: 200px;
        height: 200px;
    }
    &.xll{
        width: 400px;
        height: 400px;
    }

}