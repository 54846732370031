@import '../StyleComponents/devices.scss';
@import '../StyleComponents/constants.scss';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');

@keyframes ls-mainmenu-animation {
	0% {
		transform: rotateZ(0deg);
	}
	20% {
		transform: rotateZ(250deg);
	}
	30% {
		transform: rotateZ(180deg);
	}
	31% {
		transform: rotateZ(170deg);
	}
	40% {
		transform: rotateZ(120deg);
	}
	50% {
		transform: rotateZ(220deg);
	}
	60% {
		transform: rotateZ(330deg);
	}
	75% {
		transform: rotateZ(300deg);
	}
	80% {
		transform: rotateZ(360deg);
	}
	85% {
		transform: rotateZ(270deg);
	}
	90% {
		transform: rotateZ(180deg);
	}
	100% {
		transform: rotateZ(0deg);
	}
}

@-webkit-keyframes glowing {
	0% {
		text-shadow: 0 0 2px $theme-color, 0 0 6px $theme-color, 0 0 10px $theme-color, 0 0 14px $theme-color;
	}
	100% {
		text-shadow: 0 0 4px $theme-color, 0 0 12px $theme-color, 0 0 20px $theme-color, 0 0 28px $theme-color;
	}
}

@-webkit-keyframes glowing-lower {
	0% {
		text-shadow: 0 0 1px $theme-color, 0 0 2px $theme-color, 0 0 4px $theme-color, 0 0 5px $theme-color;
	}
	100% {
		text-shadow: 0 0 2px $theme-color, 0 0 6px $theme-color, 0 0 10px $theme-color, 0 0 14px $theme-color;
	}
}
h1.pagebrand,h1.title{
    a.LYSEP {
        font-size: inherit;
        color: $theme-color;
        animation: glowing-lower 2.5s ease-in-out infinite alternate;
        display: inline-block;
        margin: 0;
        padding: 0;
    }
}
*{
    font-family: 'Nunito', sans-serif;
}
#LoginContainerFluid{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $backgroud-color;
    .login-layout{
        width: fit-content;
        display: block;
        margin: auto;
        transform: translateY(-50%);
        top:50%;
        position: relative;
        height: fit-content;
        .logo-holder {
            img.logo {
                width: $logo-desktop-width;
                height: $logo-desktop-width;
                display: inline;
                animation: ls-mainmenu-animation 10s infinite;
                
            }
            h1.title {
                margin-left: 5px;
                top: $logo-desktop-margin;
                position: relative;
                display: inline;
                font-size: $logo-desktop-width/$logo-title-ratio;
                color: $text-color;
                font-weight: 100;

            }
            @include tablet {
                img.logo {
                    width: $logo-tablet-width;
                    height: $logo-tablet-width;
                }
                h1.title {
                    font-size: $logo-tablet-width/$logo-title-ratio;
                    top: $logo-tablet-margin;
                }
            }
            @include mobile {
                img.logo {
                    width: $logo-mobile-width;
                    height: $logo-mobile-width;
                }
                h1.title {
                    font-size: $logo-mobile-width/$logo-title-ratio;
                    top: $logo-mobile-margin;
                }
            }
        }
        
        .login-card{
            width: 25em;
            @include tablet{
                width: 18em;
            }
            @include mobile{
                width: 13em;
            }
            height: auto;
            margin:0 auto;
            background-color: $backgroud-color-2;
            #login-form{
                .input-holder{
                    label{
                        color:$desc-color;
                        display: block;
                        font-size:11px;
                    }
                    .modern-input-holder{
                        background-color: $discord-theme-back-color;
                        border:0;
                        border-radius:5px;
                        display: flex;
                        padding: 8px 10px;
                        margin-left: 3px;
                        
                        
                        
                        i{
                            display: flexbox;
                            color:$desc-color;
                            margin-right: 4px;
                            transition: 0.3s ease-in-out;
                        }
                        &:focus-within{
                            i{
                                display: flexbox;
                                color:$theme-color;
                                margin-right: 4px;
                                transition: 0.3s ease-in-out;
                            }   
                            
                        }
                        input{
                            display: flexbox;
                            background-color: $discord-theme-back-color !important;
                            border:0;
                            border-radius: 0px;
                                width: 100%;
                            color:$desc-color;
                            &:focus{
                                border:none;
                                outline: none;
                            }
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover, 
                            &:-webkit-autofill:focus, 
                            &:-webkit-autofill:active{
                                background-color: $discord-theme-back-color !important;
                            }
                        }
                    }
                    
                    margin-bottom: .5em;

                }
                .button-holder{
                    button{
                        padding:8px 30px;
                        border:none;
                        border-radius: 1px;
                        background-color:#333333;
                        color:white;
                        font-size: 14px;
                        text-transform: uppercase;
                        right: 0;
                        position: relative;
                        width:100%;

                    }
                }
                .username-holder{
                    input{padding:14px 10px;color:white;}
                }
                .password-holder{
                    input{padding:14px 10px;color:white;}
                    svg{
                        color:white;
                    }
                }
                .error-text{
                    display: block;
                    h1{
                        color:red;
                        font-size:12px;
                        margin-top: 0.5em;
                        text-align:center;
                    }
                }
                padding:1.3em 1.2em;
            }
        }
    }
}
