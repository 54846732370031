// .PanelRoot[active='1'] div.wrapper-panel{
//     width: calc(100% - 260px);
// }
div.wrapper-panel{
    right: 0;
    top:0;
    position: relative;
    display: block;
    float: right;
    width:100%;
    background-color: inherit;
    height: fit-content;
    color:#eee;
    font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
    font-weight: 600;
}